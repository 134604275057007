<template>
  <div>
    <!-- 有子级的一级菜单 -->
    <el-submenu
      :index="menuTree.menuPath"
      :show-timeout="500"
      :hide-timeout="500"
      v-if="menuTree.children && menuTree.children.length > 0"
      :key="menuTree.menuPath"
    >
      <template slot="title">
        <i
          class="iconfont"
          style="margin-right: 5px; color: #ffffff"
          v-html="menuTree.icon"
        ></i>
        <span slot="title">{{ menuTree.menuName }}</span>
      </template>
     <el-col v-if="height">
      <iot-menu
        v-for="(item, index) in menuTree.children"
        :key="index"
        :menuTree="item"
        :isChild="true"
      ></iot-menu>
     </el-col>
    </el-submenu>
    <!-- 没有子级的一级菜单 -->
    <el-menu-item
      v-else-if="menuTree.children && menuTree.children.length <= 0"
      @click="handleSelect(menuTree.menuPath)"
      :index="menuTree.menuPath"
      style="font-size: 12px"
      :key="menuTree.menuPath"
    >
      <i
        class="iconfont"
        style="margin-right: 5px"
        v-html="menuTree.icon"
        :style="getColor(menuTree.menuPath)"
      ></i>
      <span slot="title" :class="{checked:$store.state.activeMenu==menuTree.menuPath}">{{ menuTree.menuName }}</span>
    </el-menu-item>
  </div>
</template>

<script>
let that;
import theme from "../util/theme";
export default {
  name: "iotMenu",
  props: ["menuTree", "isChild"],
  data() {
    return {
      height:true,
      theme: theme,
      childMenuStyle: `height: 35px;line-height: 35px !important;`,
    };
  },
  methods: {
    getColor: function (path) {
      if (path == that.$route.fullPath) {
        return `color:${that.theme.MAIN_MENU_ACTIVE_COLOR};`;
      } else {
        return `color:#FFFFFF;`;
      }
    },
    handleSelect: function (path) {
      that.$store.commit("setActiveMenu", path);
      that.$router.push(path);
      // 点击一级菜单 关闭展开的二级菜单
      let arr = path.split('/')
      if (arr.length == 2) {
        let ul = document.querySelectorAll('.el-menu--inline')
        let menu = document.querySelectorAll('.el-submenu__title')
      ul.forEach((item,index) => {
        if (item.style.display !== 'none') {
          menu[index].click()
        }
      })
      }
      // if (children.length > 0) {
      //   that.$store.commit("setChildrenMenu", children);
      // }
      // that.$store.commit("setActiveMenu", path);
    },
  },
  beforeMount: function () {
    that = this;
  },
  mounted: function () {
    
  },
  beforeDestroy: function () {},
};
</script>
<style scoped>
.checked{
  color: rgb(255, 225, 0) !important;
}
</style>
