<template>
  <el-container>
    <el-header
      class="iot-main-header"
      :style="`background-color: ${theme.MAIN_HEAD_BACKGROUND_COLOR}`"
    >
      <ul class="iot-main-head-left">
        <li>
          <el-image
            v-if="platform.platformLogo"
            :src="
              constant.OSS_URL + constant.PLATFORM_IMG + platform.platformLogo
            "
            class="iot-main-company-image"
          ></el-image>
        </li>
        <li style="font-size: 18px">{{ platform.platformName }}</li>
        <!-- 全局背景 -->
        <!-- <li><el-color-picker v-model="globelcolor"  size="small" @change="colorState"></el-color-picker></li> -->
        <!-- <li>方案中心</li>
        <li>样品商城</li> -->
      </ul>
      <ul class="iot-main-head-right">
        <li
          v-if="$store.state.permissionArr.indexOf('work:view') > -1"
          @click="workOrderOpen"
          style="margin-right: 76px"
        >
          <span style="color: #66b1ff">文档中心</span>
        </li>
        <!-- <li style="margin-right: 35px">帮助</li>
        <li style="margin-right: 75px">文档</li> -->
        <el-badge :value="noticeTotal" class="iot-badge">
          <li @click="warning"><i class="iconfont">&#xe63c;</i></li>
        </el-badge>
        <!-- <li><i class="iconfont">&#xe886;</i></li> -->

        <li>
          <el-dropdown>
            <span class="el-dropdown-link">
              <i style="margin-right: 5px" class="iconfont">&#xe60a;</i>
              {{ $store.state.name }}，您好！</span
            >
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="update()">
                个人信息
              </el-dropdown-item>
              <el-dropdown-item @click.native="updatePwd()">
                修改密码
              </el-dropdown-item>
              <el-dropdown-item @click.native="exit()"
                >退出登录
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
      </ul>
    </el-header>
    <el-container
      class="iot-main-container"
      :style="{ height: `calc(${domHeight}px - 60px)` }"
    >
      <el-aside
        width="150px"
        class="iot-main-aside public-scroll-transparent"
        :style="`background-color:${theme.MAIN_MENU_BACKGROUND_COLOR};`"
      >
        <el-menu
          :default-active="defaultActive"
          router
          :collapse-transition="true"
          mode="vertical"
          menu-trigger="click"
          :background-color="theme.MAIN_MENU_BACKGROUND_COLOR"
          :text-color="theme.MAIN_MENU_TEXT_COLOR"
          :active-text-color="theme.MAIN_MENU_ACTIVE_COLOR"
          :unique-opened="true"
        >
          <iot-menu
            v-for="(item, index) in menuTree"
            :key="index"
            :index="'' + item.menuId"
            :isChild="false"
            :menuTree="item"
          ></iot-menu>
          <span
            style="
              color: #ffffff;
              font-size: 12px;
              display: block;
              width: 100%;
              text-align: center;
              margin-top: 40px;
            "
          >
          </span>
        </el-menu>
        <span
          :style="`background-color:${theme.MAIN_MENU_BACKGROUND_COLOR};`"
          class="iot-bottom-version"
        >
          版本号:3.11{{ $store.state.rank }}
        </span>
      </el-aside>
      <el-main class="iot-main-main">
        <router-view />
      </el-main>
    </el-container>
    <el-dialog
      top="3%"
      :title="formTitle"
      :show-close="false"
      :visible.sync="pwdDialog"
      :before-close="handleClose"
      :destroy-on-close="true"
      :close-on-press-escape="true"
      :close-on-click-modal="false"
    >
      <el-form
        :model="pwdForm"
        ref="pwdRef"
        label-width="100px"
        :rules="pwdRules"
      >
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input
            type="password"
            v-model="pwdForm.oldPassword"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            type="password"
            v-model="pwdForm.newPassword"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="重复密码" prop="repeatPassword">
          <el-input
            type="password"
            v-model="pwdForm.repeatPassword"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain size="mini" @click="submitForm"
          >确 定</el-button
        >
        <el-button type="info" plain size="mini" @click="handleClose"
          >取 消</el-button
        >
      </div>
    </el-dialog>
    <!-- <video
      ref="vidoe"
      :src="soundSrc"
      style="width: 1px; height: 1px; opacity: 0"
      muted
    ></video> -->
  </el-container>
</template>
<script>
import iotMenu from "../components/iotMenu";
import { mapState } from "vuex";
import {
  MAIN_HEAD_BACKGROUND_COLOR,
  MAIN_MENU_ACTIVE_COLOR,
  MAIN_MENU_BACKGROUND_COLOR,
  MAIN_MENU_TEXT_COLOR,
} from "../util/theme";
import { OSS_URL, PLATFORM_IMG } from "../util/constant";
import { baseUrl, addressUrl } from "../util/global";

let that;
export default {
  name: "mainPage",
  components: { iotMenu },
  props: {
    msg: String,
  },
  data() {
    return {
      globelcolor:null,
      beforeTime: 0,
      unTime: 0,
      domHeight: "",
      timeoutObj: null,
      soundSrc: require("./../assets/mp3/warning.mp3"),
      defaultActive: this.$store.state.activeMenu,
      theme: {
        MAIN_MENU_BACKGROUND_COLOR,
        MAIN_MENU_TEXT_COLOR,
        MAIN_MENU_ACTIVE_COLOR,
        MAIN_HEAD_BACKGROUND_COLOR,
      },
      constant: {
        OSS_URL,
        PLATFORM_IMG,
      },
      platform: {},
      pwdForm: {},
      formTitle: "",
      pwdDialog: false,
      pwdRules: {
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
        repeatPassword: [
          { required: true, message: "再次请输入新密码", trigger: "blur" },
        ],
      },
      wx: null,
      notice: [],
      noticeTotal: 0,
      audio: null,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.defaultActive = to.path;
      },
    },
  },
  computed: {
    ...mapState({
      menuTree: (state) => state.routes,
    }),
  },
  methods: {
    workOrderOpen() {
      window.localStorage.setItem("platformName", this.platform.platformName);
      window.open(`${addressUrl}#/Documentation`);
    },

    warning() {
      this.$router.push("/warning");
    },

    handleClose: function () {
      that.pwdDialog = false;
    },
    exit() {
      that.closeWx();
    },
  async  logout() {
   await   that.$api.mainPage.exit().then((response) => {
        if (200 === response.code) {
          window.localStorage.setItem("token", "");
          that.$store.commit("setToken", "");
          that.$store.commit("setUserId", "");
          that.$store.commit("setPermissionArr", "");
          // that.$router.push(`/iot-${window.localStorage.getItem("path")}`);
          if (!that.$store.state.path) {
            that.$router.push(`/iot-lz`);
          } else {
            that.$router.push(`/iot-${that.$store.state.path}`);
          }
     }
        return
      });
    },
    updatePwd() {
      that.pwdDialog = true;
      that.formTitle = "修改密码";
    },
    update() {
      that.$router.push("/system/baseInfo");
    },
    submitForm() {
      that.$refs["pwdRef"].validate((valid) => {
        if (valid) {
          that.$api.account.updatePwd(that.pwdForm).then((response) => {
            if (response.code == 200) {
              that.$refs.pwdRef.resetFields();
              that.$message({
                type: "success",
                message: response.msg,
              });
              that.pwdDialog = false;
            }
          });
        }
      });
    },
    getLogo() {
      that.$api.platform
        .getPlatform(that.$store.state.userId)
        .then((response) => {
          if (response.code === 200) {
            that.platform = response.data;
          }
        });
    },
    getWebsocket: function () {
      that.$api.mainPage.close(that.$store.state.tokenId).then((res) => {
        that.ws = new WebSocket(
          `wss://${baseUrl.split("https://")[1]}socket/alarm/${
            that.$store.state.userId
          }/${that.$store.state.tokenId}`
        );
        that.ws.onclose = function (event) {};
        that.ws.onopen = that.openEvent;
        that.ws.onmessage = that.messageEvent;
        that.timeoutObj = setInterval(function () {
          if (that.ws) {
            // 发送消息事件
            that.ws.send("Heartbeat");
            // console.log("发送消息");
          }
        }, 600000);
      });
    },
    openEvent: function () {
      that.$api.mainPage.open();
    },
    colorState(value) {
      document.querySelector('body').setAttribute('style', `background-color:${value}`)
      // document.querySelector('div').setAttribute('style', `background-color:${value}`)
      // document.querySelector('div').setAttribute('style', `color:${value}`)
    },
    messageEvent: function (message) {
      if (message.data == "Heartbeat") {
        return;
      }
      if (!message.data) {
        return;
      }
      let msg = JSON.parse(message.data);

      that.nocice = msg.ruleInfoList;
      that.noticeTotal = msg.normalCount;

      if (msg.ruleInfoList.length > 0) {
        let audio = new Audio();
        audio.src = require("../assets/mp3/warning.mp3");
        audio.play();
        msg.ruleInfoList.forEach((element) => {
          setTimeout(() => {
            if (element) {
              that.openMessage(element);
            }
          }, 500);
        });
      }
    },
    playSound: function () {
      let audio = new Audio();
      audio.src = that.soundSrc;
      audio.autoplay = true;
      audio.play();
    },
    closeWx: function () {
      clearInterval(that.timeoutObj);
      that.$api.mainPage.close(that.$store.state.tokenId).then((res) => {
        that.logout();
      });
    },
    openMessage(data) {
      this.$notify({
        offset: 50,
        duration: 10000,
        dangerouslyUseHTMLString: true,
        message: `<div style="width:400px;font-size: 15px;color: #000;height:100px"><div style="height:20px;line-height:20px;display:flex">
        <i style="color:#F56C6C;font-size:20px"  class="el-icon-warning"></i>
        <div style="margin-left:8px;display:inline-block;font-size: 15px;color: #000;">${data.projectName}</div>
        </div><br/>${data.alarmInfo}
        </div>`,
      });
    },
    async closeWindow() {
      await that.logout()  
      await that.$api.mainPage.exit().then();
      window.localStorage.removeItem('token')
    },
    beforeunloadFn(e) {
      that.beforeTime = new Date().getTime();
    },
  },
  beforeCreate() {
    that = this;
  },
  mounted() {
    that.getLogo();
    that.getWebsocket();
    that.domHeight = document.body.scrollHeight;
    window.addEventListener("beforeunload", (e) => that.beforeunloadFn(e));
    window.addEventListener("unload", (e) => {
      that.unTime = new Date().getTime() - that.beforeTime;
      clearInterval(that.timeoutObj);
      if (that.unTime <= 5) {
        that.closeWindow(); //浏览器关闭
      } 
    });
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", (e) => this.beforeunloadFn(e));
    // window.removeEventListenerventListener("unload")
        window.removeEventListener("unload", (e) => {
      this.unTime = new Date().getTime() - this.beforeTime;
      clearInterval(this.timeoutObj);
      if (this.unTime < 5) {
        this.closeWindow(); //浏览器关闭
      }
    });
   },
  // destroyed() {
  //   window.removeEventListener('beforeunload', (e) => this.beforeunloadFn(e))
  //   window.removeEventListener("unload", (e) => {
  //     this.unTime = new Date().getTime() - this.beforeTime;
  //     clearInterval(this.timeoutObj);
  //     if (this.unTime < 5) {
  //       this.closeWindow(); //浏览器关闭
  //     }
  //   });
  // }
};
</script>

<style scoped>
.iot-main-header {
  height: 50px !important;
  padding: 0;
}

.iot-main-head-left,
.iot-main-head-right {
  padding: 0;
  display: flex;
  justify-content: center;
  height: 60px;
}

.iot-main-head-left {
  float: left;
  line-height: 40px;
  height: 100%;
}

.iot-main-head-right {
  float: right;
  margin-right: 30px;
}

.iot-main-head-left li,
.iot-main-head-right li {
  display: inline-block;
  height: 40px;
  font-size: 16px;
  cursor: pointer;
  line-height: 40px;
}

.iot-main-head-left li {
  margin: 5px 35px 5px 0;
}

.iot-main-head-right li {
  margin: 5px 25px 5px 0;
}

.iot-main-head-left li:first-child {
  margin: 5px 8px 5px 35px;
}

.iot-main-head-left li:nth-child(0) {
  cursor: default !important;
}

.iot-main-company-image {
  width: 45px;
  height: 40px;
}

ul {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

.iot-main-container {
  height: calc(969px - 60px);
  overflow: hidden;
}

.iot-main-container >>> .el-menu {
  border-right: 0px !important;
}

.iot-main-aside,
.iot-main-main {
  height: 101%;
}
.iot-main-aside {
  padding-bottom: 50px;
}
.iot-main-main {
  padding: 0;
}
.iot-bottom-version {
  position: fixed;
  display: block;
  width: 150px;
  bottom: 10px;
  left: 0px;
  font-size: 12px;
  color: #dfdfdf;
  text-align: center;
  line-height: 50px;
}
.iot-badge >>> .el-badge__content.is-fixed {
  margin-top: 13px;
  margin-right: 20px;
}
.el-icon-warning,
.el-icon-warning:before {
  color: red !important;
}
</style>
